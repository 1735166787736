<template>
  <div class="profil">


    <div v-if="is_loading" class="loading">
      <loadingItem medium />
    </div>
    <div v-else>

            <div class="naglowek">
                <h1>Wprowadź kod:</h1>
            </div>
            <div class="usernames">
              <p>Sprawdź prywatną skrzynkę pocztową.<br>Znajdziesz tam mail z jednorazowym<br>kodem dostępu!</p>
              <input v-model="password_entered" type="text" placeholder="Wpisz jednorazowy kod"/>

              <!-- 
              <div class="checkbox" @click="checkbox_clicked" >
                <div class="left">
                  <i v-if="this.login_checkbox" class="fa-solid fa-circle-check"></i>
                  <i v-else class="fa-regular fa-circle"></i>
                </div>
                <div class="right">
                  <span > Uroczyście przysięgam,<br>że knuję coś niedobrego.</span>
                </div>
              </div>
              -->          
                            
              <span class="error komunikat" v-if="is_error_checkbox">
                Checkbox nie został zaznaczony.
              </span>
              <span class="error komunikat" v-if="is_error_password">
                Wprowadzony kod jest niepoprawny.
              </span>
              <span class="error komunikat" v-if="is_password_empty">
                Nie wprowadzono kodu
              </span>

              <button 
                @click="login(user_id)"
              >
              Zaloguj                  
              </button>


            </div>


    </div>

  </div>
</template>
<script>

import loadingItem from "@/components/loadingItem.vue";


export default {
  name: "LoginPassword",
  components: {
    loadingItem,
  },
  props:{
    user_id: Number,
  },
  data() {
    return {


      my_commision: 2,
   
      is_loading: false,
      is_error_checkbox: false,
      is_error_password: false,
      is_password_empty: false,
      password_entered: "",
      login_checkbox: true, //todo false



    };
  },
  
  created() {

    console.log("loggedUserId: "+this.$store.state.loggedUserId)




  },
  methods: {
    login(user_id){
      console.log("log in " + user_id)

      if(!this.login_checkbox){
        this.is_error_checkbox = true;
      } else{
        this.is_error_checkbox = false;
      }
      if(this.password_entered == ''){
        this.is_password_empty = true;
      }

      if(this.is_error_checkbox || this.is_password_empty)
        return;


      this.is_loading = true;
      //console.log("password: " + this.password_entered)
      this.password_entered = "";


      // pozostawienie tylko cyfr
      var passwd = this.password_entered.replace(/\D/g,'');
      console.log(passwd)


      this.$router.push("kalendarz") //todo do axios


      setTimeout(() => {
        this.is_loading = false;



      }, 400)

    },

    checkbox_clicked(){
      this.login_checkbox = !this.login_checkbox;
    },

  },

  watch:{
    password_entered(){
      if(this.is_password_empty)
        this.is_password_empty = false;
    }
  }
};
</script>
<style lang="scss" scoped>



.usernames{
  margin-left:20px;
  margin-right:20px;
}

button{
  width: 70%;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  
}

.checkbox{
  width: 70%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;  
}

h1{
  margin-bottom: 30px;
}

.left{
  width: 10%;  
  display: inline-block;
  height: 40px;
  padding-top:5px;
  

}
.right{
  padding-right: 5%;
  width: 80%;
  float: right;
  height: 40px;

}

.komunikat{
  display: block;
}
</style>